import React from 'react';
import './landingPage.css'
import {Link} from "react-router-dom";
import {Card} from "antd";
const LandingPage = () => {
    return (
        <div>
            <Card
            >
                <Link to={"/home"}>
                <button data-label="Register" className="rainbow-hover">
                    <span className="sp">Truy cập</span>
                </button>
            </Link>

            </Card>

            <Card
                title="LANDING PAGEEEEEEEEEEEEEE"
            >

            </Card>

        </div>
    );
};

export default LandingPage;
import React, {useEffect, useState} from 'react';
import {getListBankWithName} from "../../../../requestToAPI/ManagerBank";
import {Table, Tag} from "antd";
import {useNavigate} from "react-router-dom";

const TableListBank = ({bank, title}) => {
    let navigate = useNavigate()
    // Xử lý hiển thị danh sách tài khoản
    const [listBank, setListBank] = useState('')
    const [loading, setLoading] = useState(true)
    const [columns] = useState(
        [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 40
        }, {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            render: ((_, {username}) => {
                return (
                    <a>{username}</a>
                )
            })
        }, {
            title: 'Số tài khoản',
            dataIndex: 'numberAccount',
        }, {
            title: 'Trạng thái',
            dataIndex: 'isWorking',
            render: (_, {isWorking}) => {
                let color;
                let text;
                if (isWorking === '?') {
                    color = 'orange'
                    text = "????"
                } else if (isWorking === '1') {
                    color = 'green'
                    text = "WORKING"
                } else if (isWorking === null) {
                    color = 'rgba(0, 0, 0, 0.88)'
                    text = "NULL"
                } else if (isWorking === '0') {
                    color = 'rgba(255,0,0,0.88)'
                    text = "STOPED"
                }
                return (
                    <Tag color={color} key={isWorking}>
                        {text}
                    </Tag>
                )

            }
        }
        ]
    )
    useEffect(() => {
        getListBankWithName(bank)
            .then((data) => {
                if (data.status === false) {
                    setListBank(false)
                    setLoading(false)
                    console.log('Không có data')
                } else {
                    console.log('Tìm thấy data')
                    setListBank(data.data)
                    setLoading(false)
                }
            })
    }, [])
    const clickRow = (e, record) => {
        console.log(record.id)
        let bankUrl
        if (bank === 'vcb'){
            bankUrl = 'vietcombank'
        }
        navigate(`/bank/${bankUrl}/bankinfo/${record.id}`)
    }

    return (
        <Table
            dataSource={listBank}
            columns={columns}
            rowKey={(listBank) => listBank.id}
            bordered
            title={() => title}
            size={"middle"}
            loading={loading}

            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        clickRow(event, record)
                    }
                }
            }}
        />
    );
};

export default TableListBank;

import React from 'react';
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from 'react';
import Sidebar from "../../layouts/Sidebar";
import HeaderBar from "../../layouts/HeaderBar";
import {Descriptions, Layout, Space} from "antd";
import {checkToken} from "../../requestToAPI"
import DescriptionsItem from "antd/lib/descriptions/Item";

const {Content} = Layout;

const Home = () => {
    const [{balance, create_at, email, id, name}, setUserInfo] = useState([])
    let navigate = useNavigate()
    useEffect(() => {
        checkToken()
            .then((data) => {
                setUserInfo(data)
                if (data.status === false) {
                    navigate('/login')
                }
            })
    }, [navigate])
    return (
        <Layout>
            <HeaderBar/>
            <Layout style={{
                minHeight: "100vh"
            }}>
                <Sidebar/>
                <Content
                    style={{
                        margin: '10px 20px'
                    }}
                >
                    <Space.Compact block>
                        <Descriptions title="Thông tin tài khoản" bordered size='small'>
                            <DescriptionsItem label="ID">{id}</DescriptionsItem>
                            <DescriptionsItem label="Họ và tên">{name}</DescriptionsItem>
                            <DescriptionsItem label="Email">{email}</DescriptionsItem>
                            <DescriptionsItem label="Số dư">{balance}đ</DescriptionsItem>
                            <DescriptionsItem label="Ngày tham gia">{create_at}</DescriptionsItem>
                        </Descriptions>
                    </Space.Compact>
                    <Space>
                        <h1>sdfksdkfjgsdkfjaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaahksjf</h1>
                    </Space>
                </Content>
            </Layout>
        </Layout>
    );
};
export default Home;
import React, {useEffect, useState} from 'react';
import HeaderBar from "../../../layouts/HeaderBar";
import {Button, Card, Col, Input, Layout, Modal, Popconfirm, Row, Switch, Table, Tag, Typography} from "antd";
import Sidebar from "../../../layouts/Sidebar";
import {Content} from "antd/lib/layout/layout";
import {useAuthCheck} from '../../../useAuthCheck';
import {requestSimpleGetApi} from "../../../requestToAPI";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        align: "center",
        width: 80
    }, {
        title: "Username",
        dataIndex: "username",
        align: "center",

    }, {
        title: "Thời gian",
        dataIndex: "tranDate"
    }, {
        title: "Trans ID",
        dataIndex: "tranId",
        width: 120
    }, {
        title: "Loại",
        dataIndex: "in_out",
        width: 60,
        align: "center",
        render: (_, {in_out}) => {
            let color;
            let text;
            if (in_out === '+') {
                color = 'green'
                text = "+"
            } else if (in_out === '-') {
                color = 'red'
                text = "-"
            } else {
                color = 'black'
                text = "?"
            }
            return (
                <Tag color={color} key={in_out}>
                    {text}
                </Tag>
            )
        }

    }, {
        title: "Giá trị",
        dataIndex: "amount"
    }, {
        title: "Nội dung",
        dataIndex: "memo",
        width: 400
    }, {
        title: "Ngân hàng",
        dataIndex: "bankName"
    }, {
        title: "Callback",
        dataIndex: "callBackNumber"
    }
]

function CustomFooter() {
    return (
        <div>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
            <Button type="primary">Primary Button</Button>
        </div>

    );
}
const Histories = () => {
    //-------------------------------------------------------------
    useAuthCheck();
    //--------------------------------------------------------------
    const [loadingTable, setLoadingTable] = useState(true)
    const [listHistories, setListHistories] = useState('')
    useEffect(() => {
        requestSimpleGetApi('info/list-histories')
            .then((data) => {
                if (data.status === false) {
                    setListHistories(false)
                    console.log('Không có data')
                } else {
                    console.log('Tìm thấy data')
                    setListHistories(data.data)
                    setLoadingTable(false)
                }
            })
    }, [])
    return (
        <Layout>
            <HeaderBar/>
            <Layout style={{
                minHeight: "100vh",
                maxWidth: "100vw"
            }}>
                <Sidebar/>
                <Content style={{margin: '10px 20px'}}>
                    <>
                        <Table
                            columns={columns}
                            bordered
                            rowKey={(listHistories) => listHistories.id}
                            title={() => "Lịch sử giao dịch"}
                            footer={()=> <CustomFooter/>}
                            dataSource={listHistories}
                            loading={loadingTable}
                            scroll={{
                                x: 1300,
                                y: 550
                            }}
                        />

                    </>
                </Content>
            </Layout>

        </Layout>
    );
};

export default Histories;

import React from 'react';
import {useNavigate} from "react-router-dom";

import {Menu, Button, Layout, theme, Dropdown, Space, Avatar, Card} from "antd";
import {MenuUnfoldOutlined, UserOutlined} from '@ant-design/icons'

const {Header} = Layout;

const HeaderBar = () => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    let navigate = useNavigate();
    const handleMenuClick = (e) => {
        console.log('click', e);
        navigate(e.key)

    };
    const items = [
        {
            label: 'Thông tin tài khoản',
            key: '/info',
            // icon:   ''
        }, {
            label: 'Đăng xuất',
            key: '/logout'
        }, {
            label: 'Nạp tiền',
            key: "/account/naptien"
        }
    ]
    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    return (
        <Header>
            <Space.Compact>
                    <Dropdown
                        menu={menuProps}
                        placement={"bottomRight"}
                        trigger={"click"}
                    >
                        <Avatar icon={<UserOutlined/>}>
                        </Avatar>
                    </Dropdown>
            </Space.Compact>

        </Header>
    );
};

export default HeaderBar;
import React from 'react';
import { useState, useEffect } from 'react';

import {Button, Layout, Table} from "antd";
import HeaderBar from "../../../layouts/HeaderBar";
import Sidebar from "../../../layouts/Sidebar";
import {Content} from "antd/es/layout/layout";
// import {checkToken} from "../../../requestToAPI";
import {Link, useNavigate} from "react-router-dom";
import TableListBank from "../element/table/TableListBank"
import {useAuthCheck} from "../../../useAuthCheck";

const BIDVIndex = () => {
    //----------------------------------------------------------------------------------
    let navigate = useNavigate()
    useAuthCheck();
    //----------------------------------------------------------------------------------

    return (
        <Layout>
            <HeaderBar/>
            <Layout style={{
                minHeight: "100vh"
            }}>
                <Sidebar/>
                <Content style={{margin: '10px 20px'}}>
                    <Button
                        type={"primary"}
                        size={"large"}
                        onClick={() => navigate('add')}
                    >Thêm tài khoản
                    </Button>
                    <TableListBank
                        bank="bidv"
                        title="Danh sách tài khoản Vietcombank"
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default BIDVIndex;
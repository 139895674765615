import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    FileOutlined,
    BankTwoTone,
    TeamOutlined,
    UserOutlined,
    HomeTwoTone,

} from '@ant-design/icons';
import { createFromIconfontCN } from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Layout, Menu, theme} from 'antd';
import config from "../../config";

const MyIcon = createFromIconfontCN({
    scriptUrl: config.ICON_FONT_CN, // generate in iconfont.cn
});
const {Sider} = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    };
}

const items: MenuItem[] = [
    getItem('Trang chủ', '/home', <HomeTwoTone/>),
    getItem('Thanh toán', '/billing', <BankTwoTone/>),
    getItem('Cổng thanh toán', 'sub1', <MyIcon type="icon-all"/>, [
        getItem('Ví điện tử', 'sub2', <MyIcon type="icon-money-wallet"/>, [
            getItem('Momo', '/wallet/momo'),
            getItem('ViettelPay', '/wallet/viettelpay'),
            getItem('ZaloPay', '/wallet/zalopay'),
        ]),
        getItem('Ngân hàng', 'sub3', <MyIcon type="icon-bank"/>, [
            getItem('Danh sách', '/bank'),
            getItem('Vietcombank', '/bank/vietcombank'),
            getItem('Vietinbank', '/bank/vietinbank'),
            getItem('MB Bank', '/bank/mbbank'),
            getItem('BIDV', '/bank/bidv')
        ])

    ]),
    getItem('Lịch sử giao dịch', '/bank/histories', <MyIcon type="icon-log"/>),
    getItem('Team', 'sub4', <TeamOutlined/>, [
        getItem('Team 1', '6'),
        getItem('Team 2', '8')
    ]),
    // getItem('Ngân Hàng', '/bank', <FileOutlined/>),
];

const Sidebar = () => {
    let navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(() => {
        let dataLocalStorage = localStorage.getItem('sidebarCollapsed')
        return dataLocalStorage !== 'false';
    });

    function changeCollapsed(value) {
        localStorage.setItem('sidebarCollapsed', value)
        setCollapsed(value)
    }

    const handleClickSidebar = (item) => {
        console.log(item.key)
        navigate(item.key);
    }
    return (
        <Sider
            className="sider"
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => changeCollapsed(value)
            }>
            <Menu
                onClick={handleClickSidebar}
                theme="dark"
                mode="inline"
                items={items}
            />
        </Sider>
    );
};

export default Sidebar;
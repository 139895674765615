import HeaderBar from "../../../layouts/HeaderBar";
import {Button, Checkbox, Form, Input, Layout, Modal} from "antd";
import Sidebar from "../../../layouts/Sidebar";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import './index.css';

import {requestPostAPI} from "../../../requestToAPI"
import {useAuthCheck} from "../../../useAuthCheck";

const {Content} = Layout;
const Login = () => {
    //-------------------------------------------------------------
    let navigate = useNavigate()
    useAuthCheck();
    //--------------------------------------------------------------
    const [modal, contextHolder] = Modal.useModal();
    const [loadings, setLoadings] = useState(false);
    const showModel = (status, data) => {
        let secondsToGo = 5;


        if (status === false) {
            let instance = modal.error({
                title: `Có lỗi trong ${data.error}`,
                content: `Thông báo sẽ tắt sau ${secondsToGo} giây!`,
                okText: "Thử lại",
            });
            const timer = setInterval(() => {
                secondsToGo -= 1;
                instance.update({
                    content: `Thông báo sẽ tắt sau  ${secondsToGo} giây.`,
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(timer);
                instance.destroy();
            }, secondsToGo * 1000);

        } else {
            let instance = modal.success({
                title: 'Đăng nhập thành công',
                content: `Di chuyển về trang chủ sau ${secondsToGo} giây.`,
                okText: "Truy cập",
                // afterClose: handleClose
                afterClose: () => {
                    navigate('/home');
                }
            });
            const timer = setInterval(() => {
                secondsToGo -= 1;
                instance.update({
                    content: `Di chuyển về trang chủ sau ${secondsToGo} giây.`,
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(timer);
                instance.destroy();
            }, secondsToGo * 1000);
        }
        setLoadings(status);


    };
    const handleSubmit = async (values) => {
        setLoadings(true)
        let formData = {
            username: values.username,
            password: values.password
        }
        let data = await requestPostAPI('account/login', formData, false)
        console.log("Response: " + JSON.stringify(data))
        if (data.status) {
            console.log('Request login success!');
            localStorage.setItem('token', data.token);
            showModel(true, data)
        } else {
            console.error('Đăng nhập tài khoản thất bại.');
            // Xử lý hiển thị thông báo lỗi đăng ký tài khoản
            showModel(false, data)
        }
    };

    return (
        <Layout>
            <HeaderBar/>
            <Layout>
                <Sidebar/>
                <Content style={{margin: '10px 20px'}}>
                    <h1>Đăng nhập</h1>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={(values) => {
                            handleSubmit(values)
                        }}
                        onFinishFailed={(error) => {
                            console.log(error)
                        }}
                    >
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[{
                                required: true,
                                message: "Thiếu tên tài khoản",
                            }, {
                                whitespace: true,
                                message: "Không thể điền khoảng trắng"
                            }, {
                                min: 3,
                                message: "Tối thiểu 3 kí tự"
                            }]}
                            hasFeedback
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
                        </Form.Item>

                        <Form.Item name="password" label="Password"
                                   rules={[
                                       {
                                           required: true,
                                           message: "Vui lòng nhập mật khẩu"
                                       },
                                       {
                                           min: 3,
                                           message: "Tối thiểu 3 kí tự"
                                       },
                                   ]
                                   }>
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                allowClear
                                placeholder="Nhập mật khẩu"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <a className="login-form-forgot" href="">
                                Forgot password
                            </a>
                        </Form.Item>
                        <>
                            {contextHolder}
                        </>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loadings}
                            >
                                Đăng nhập
                            </Button>
                            Or <a href="">register now!</a>
                        </Form.Item>
                    </Form>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Login;
import LandingPage from "../components/pages/home/landingPage";
import Register from "../components/pages/account/register";
import Login from "../components/pages/account/login";
import Logout from "../components/pages/account/logout";
import NapTien from "../components/client/account/NapTien";


import Home from "../components/pages/home"
import ListBank from "../components/pages/bank/manager/ListBank";
import Histories from "../components/pages/bank/manager/Histories";
// import BankInfo from "../components/pages/bank/manager/BankInfo";
import MBBIndex from "../components/pages/bank/mbbank";
//Import Vietcombank
import VietcombankIndex from "../components/pages/bank/vietcombank/VietcombankIndex";                       //Index Vietcombank
import AddBankVietcombank from "../components/pages/bank/vietcombank/AddBankVietcombank";                   //Add Account
import InfoBankAccountVietcombank from "../components/pages/bank/vietcombank/InfoBankAccount";              //Info Account
//Import BIDV
import BIDVIndex from "../components/pages/bank/bidv/BIDVIndex";                                            //Index


//Public Routes
const publicRoutes = [
    {path: '/register', component: Register},
    {path: '/login', component: Login},
    {path: '/logout', component: Logout},
    {path: '/account/naptien', component: NapTien},

    {path: '/', component: LandingPage},
    {path: '/home', component: Home},
    // Liên quan đến tài khoản ngân hàng
    {path: '/bank', component: ListBank},           //Danh sách tài khoản bank
    // {path: '/bank/bankinfo/:id', component: BankInfo},
    {path: '/bank/histories', component: Histories},
    //Routes Vietcombank
    {path: '/bank/vietcombank', component: VietcombankIndex},
    {path: '/bank/vietcombank/add', component: AddBankVietcombank},
    {path: '/bank/vietcombank/bankinfo/:idBank', component: InfoBankAccountVietcombank},

    {path: "/bank/mbbank", component: MBBIndex},
    //Routes BIDV
    {path: "/bank/bidv", component: BIDVIndex}

]
const privateRoutes = []
export {
    publicRoutes, privateRoutes
}
import config from "../../config";
// export async function requestPostAPI(uri, formData, hasToken = true) {
//     const token = localStorage.getItem('token')
//     if (hasToken) {
//         if (!token) {
//             return {status: false, des: 'Have not token'}
//         }
//     }
//     let url = config.API_BASE_URL + uri
//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer " + token);
//
//     const formdata = new FormData();
//     Object.entries(formData).forEach(([key, value]) => {
//         formdata.append(key, value);
//     });
//
//     const requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow'
//     };
//     const response = await fetch(url, requestOptions)
//     if (response.ok) {
//         console.log(`Send request ${uri} success!`);
//         return await response.json()
//     } else {
//         console.error(`Send request ${uri} failed!`);
//         return await response.json()
//     }
// }
export async function getListBankWithName(bank) {
    const token = localStorage.getItem('token')
    if (!token) {
        return {status: false, des: 'Have not token'}
    }
    let url = `${config.API_BASE_URL}manage/bank/bank-account?bankName=${bank}`
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions)
    if (response.ok) {
        console.log(`Send request GET ${url} success!`);
        return await response.json()
    } else {
        console.error(`Send request GET ${url} failed!`);
        return {status: false, des: 'Request failed'}
        // return await response.json()
    }
}
import React from 'react';
import HeaderBar from "../../layouts/HeaderBar";
import {Col, Layout, Row, Space} from "antd";
import Sidebar from "../../layouts/Sidebar";
import {Content} from "antd/es/layout/layout";

const NapTien = () => {

    return (
        <Layout>
            <HeaderBar/>
            <Layout>
                <Sidebar/>
                <Content
                    style={{
                        margin: '10px 20px'
                    }}
                >

                    <Space>
                        <Row>
                            <Col span={9}>

                            </Col>
                            <Col span={9} offset={1}>
                                col-8dsfsdgfdfgdfvxcvhxkfhsdkjfjksfslkdhf jksdhfdgdfgdfgdfgdfgdfgdfgfjksdfjbsdjfbjksd sdfb sdmfmsd,fbmdbf sdfsdfjksdfjksdfjksdf
                            </Col>
                        </Row>
                    </Space>
                </Content>
            </Layout>
        </Layout>
    );
};

export default NapTien;
import React, {useEffect} from 'react';
import {logoutToken} from "../../../requestToAPI";
import {useNavigate} from "react-router-dom";

const Logout = () => {
    let navigate = useNavigate();

    useEffect(() => {
        // Gửi yêu cầu đến server để kiểm tra tính hợp lệ của token
        logoutToken()
            .then((data) => {
                console.log(data)
                if (data.status === false) {
                    navigate('/')
                } else {
                    navigate('/')
                }
            })


    }, [])
    return (
        <div>
            Trang logout
        </div>
    );
};

export default Logout;
import React from 'react';
import {useEffect, useState} from 'react';
import HeaderBar from "../../../layouts/HeaderBar";
import Sidebar from "../../../layouts/Sidebar";
import {Content} from "antd/lib/layout/layout";
import {useNavigate, useParams} from "react-router-dom";
import {requestPostAPI, requestCustomMethod, requestGetAPI} from "../../../requestToAPI";
import {Button, Card, Col, Input, Layout, Modal, Popconfirm, Row, Switch, Typography} from "antd";
import NotifyLogin from "../element/modal/vietcombank/NotifyLogin";
import Paragraph from "antd/lib/typography/Paragraph";
import {useAuthCheck} from "../../../useAuthCheck";

const InfoBankAccountVietcombank = () => {
    //-------------------------------------------------------------
    let navigate = useNavigate()
    useAuthCheck();
    //--------------------------------------------------------------
    // Lấy thông tin 1 tài khoản ngân hàng từ API
    let [infoBank, setInfoBank] = useState('')
    let [statusAutoScan, setStatusAutoScan] = useState(false)
    let [loadingStatusScan, setLoadingStatusScan] = useState(true)
    let {idBank} = useParams()
    useEffect(() => {
        requestGetAPI(`manage/bank/info?idBank=${idBank}`)
            .then((data) => {
                console.log(data)
                if (data.id) {
                    setInfoBank(data)
                    setLoadingStatusScan(false)
                    setStatusAutoScan(!!data.isQueue)
                } else {
                    navigate('/bank/vietcombank')
                }
            })
    }, [])
    // Tuỳ chỉnh toggle trạng thái scan
    const changeStatusScan = (checked: boolean) => {
        console.log(`switch to ${checked}`);
        let postData = {
            idBank: idBank,
            target: 'isQueue',
            value: checked ? 1 : 0
        }
        setLoadingStatusScan(true)
        requestPostAPI('manage/bank/toggle-bank-account', postData)
            .then((data) => {
                setStatusAutoScan(checked)
                setLoadingStatusScan(false)
            })
    }
    // Xoá tài khoản
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showConfirmDeleteAccount = () => {
        setOpenDeleteAccount(true)
    }
    const hideConfirmDeleteAccount = () => {
        setOpenDeleteAccount(false)
    }
    const confirmDeleteAccount = () => {
        setConfirmLoading(true);
        requestCustomMethod(`manage/bank/bank-account?idBank=${idBank}`, 'DELETE')
            .then((data) => {
                console.log(data)
                setConfirmLoading(false);
                navigate('/bank/vietcombank')
            })
    }
    const handleNull = (data) => {
        if (!data) return "Khong xac dinh"
        return data
    }
    //Đăng nhập VCB
    const [loadingLogin, setLoadingLogin] = useState(false)
    const [modalLogin, setModalLogin] = useState(false)
    const [tileModalLogin, setTileModalLogin] = useState('')
    const [desModalLogin, setDesModalLogin] = useState('')
    const handleOk = () => {
        setModalLogin(false);
    };

    const loginVCB = async () => {
        setLoadingLogin(true)
        requestGetAPI(`vietcombank/login?idBank=${idBank}`)
            .then((data) => {
                console.log(data)
                if (data.status === true && data.success === true) {
                    console.log("Dang nhap thanh cong")
                    setTileModalLogin("Dang nhap thanh cong")
                    setDesModalLogin(data.message)
                    setModalLogin(true)

                } else if (data.success && data.message === "needOTP") {
                    setTextLoginOTP(`Bạn vui lòng nhập OTP đăng nhập, mã giao dịch ${data.result.challenge}`)
                    setModalOTPLogin(true)
                } else {
                    console.log("Đăng nhập thất bại")
                    setTileModalLogin("Đăng nhập thất bại")
                    setDesModalLogin(data.message)
                    setModalLogin(true)

                }
                setLoadingLogin(false)
            })
    }
    // Xử lý khi đăng nhập thành công và cần nhập OTP
    const [inputOTPLogin, setInputOTPLogin] = useState('')
    const [modalOTPLogin, setModalOTPLogin] = useState(false)
    const [textLoginOTP, setTextLoginOTP] = useState('')

    const loginPostOTP = async () => {
        setLoadingLogin(true)
        requestGetAPI(`vietcombank/otplogin?idBank=${idBank}&otp=${inputOTPLogin}`)
            .then((data) => {
                console.log(data)
                if (data.success === true) {
                    console.log("Dang nhap thanh cong")
                    setTileModalLogin("Đăng nhập thành công")
                    setDesModalLogin(data.message)
                    setModalLogin(true)
                } else {
                    console.log("Đăng nhập thất bại")
                    setTileModalLogin("Đăng nhập thất bại")
                    setDesModalLogin(data.message)
                    setModalLogin(true)
                }
                setLoadingLogin(false)
            })
    }
    const handleOkOTPLogin = async () => {
        console.log(inputOTPLogin)
        console.log("Thuc hien post OTP")
        await loginPostOTP()
        // setModalLogin(false);
        await setModalOTPLogin(false)
    };
    const handleCancelOTPLogin = () => {
        setModalOTPLogin(false);
    };

    return (
        <Layout>
            <HeaderBar/>
            <Layout style={{
                minHeight: "100vh",
                maxWidth: "100vw"
            }}>
                <Sidebar/>
                <Content style={{margin: '10px 20px'}}>
                    <>
                        <NotifyLogin
                            ModalOpen={modalLogin}
                            title={tileModalLogin}
                            des={desModalLogin}
                            handleOk={handleOk}
                        />

                        <Card>
                            <Typography.Text
                            >
                                <Paragraph>
                                    {JSON.stringify(infoBank)}
                                </Paragraph>
                            </Typography.Text>
                        </Card>
                        <Card>
                            <p>Tên tài khoản: {infoBank.username}</p>
                            <p>Số tài khoản: {infoBank.numberAccount}</p>
                            <p>Ngân hàng: {infoBank.bankName}</p>
                            <p>Trạng thái: {handleNull(infoBank.isWorking)}</p>
                            <p>Cập nhật: {handleNull(infoBank.updated_at)}</p>
                            <p>Ngày thêm: {handleNull(infoBank.created_at)}</p>
                        </Card>
                        <Row gutter={[12, 12]}>
                            <Col>
                                <Card
                                    title="Trạng thái"
                                    style={{
                                        width: 200,
                                        height: 150
                                    }}
                                >

                                </Card>
                            </Col>
                            <Col>
                                <Card
                                    title="Đăng nhập"
                                    style={{
                                        width: 200,
                                        height: 150
                                    }}>
                                    <Button
                                        type="primary"
                                        onClick={loginVCB}
                                        loading={loadingLogin}
                                    >
                                        Đăng nhập
                                    </Button>
                                </Card>
                            </Col>
                            <Col>

                                <Card
                                    title="Tự động scan"
                                    style={{
                                        width: 200,
                                        height: 150
                                    }}
                                >
                                    <Switch
                                        onChange={changeStatusScan}
                                        checkedChildren="Bật"
                                        unCheckedChildren="Tắt"
                                        loading={loadingStatusScan}
                                        checked={statusAutoScan}
                                    />
                                </Card>
                            </Col>
                            <Col>
                                <Card
                                    title="Quản lý tài khoản"
                                    style={{
                                        width: 200,
                                        height: 150
                                    }}
                                >
                                    <Popconfirm
                                        title="Xoá tài khoản"
                                        description="Bạn có muốn xoá tài khoản này khỏi hệ thống"
                                        okText="Xoá"
                                        open={openDeleteAccount}
                                        cancelText="Huỷ"
                                        onCancel={hideConfirmDeleteAccount}
                                        onConfirm={confirmDeleteAccount}
                                        okButtonProps={{
                                            loading: confirmLoading,
                                        }}
                                    >
                                        <Button
                                            danger
                                            onClick={showConfirmDeleteAccount}
                                        >Xoá tài khoản</Button>
                                    </Popconfirm>
                                </Card>
                            </Col>
                        </Row>
                        <Modal
                            title="Vui lòng nhập OTP"
                            open={modalOTPLogin}
                            onOk={handleOkOTPLogin}
                            onCancel={handleCancelOTPLogin}
                            width={450}
                        >
                            <p>{textLoginOTP}</p>
                            <br/>
                            <Input
                                value={inputOTPLogin}
                                size={"large"}
                                onChange={e => setInputOTPLogin(e.target.value)}
                                style={{
                                    width: '30%'
                                }}
                            />
                        </Modal>
                    </>
                </Content>
            </Layout>

        </Layout>
    );
};

export default InfoBankAccountVietcombank;

import React from 'react';
import {useState, useEffect } from 'react';
import Sidebar from "../../../layouts/Sidebar";
import {Button, Form, Input, Layout, theme, Modal} from "antd";
import {Link, useNavigate } from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import HeaderBar from "../../../layouts/HeaderBar";
const Register = () => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const [loadings, setLoadings] = useState([]);
    const enterLoading = (status) => {
        setLoadings(true);
    };
    let navigate  = useNavigate();
    // const handleClose = navigate('/');
    let handleClose = function () {
        console.log("Đã đóng modal")
        navigate('/');
    }
    const [modal, contextHolder] = Modal.useModal();
    const countDown = (status, data) => {
        let secondsToGo = 5;
        if (status === false){
            let instance = modal.error({

                title: `Có lỗi trong ${data.error}`,
                content: `Thông báo sẽ tắt sau ${secondsToGo} giây.`,
            });
            const timer = setInterval(() => {
                secondsToGo -= 1;
                instance.update({
                    content: `Thông báo sẽ tắt sau  ${secondsToGo} giây.`,
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(timer);
                instance.destroy();
            }, secondsToGo * 1000);

        }else {
            localStorage.setItem('token', data.token);
            let instance = modal.success({
                title: 'Đăng ký thành công',
                content: `Di chuyển về trang chủ sau ${secondsToGo} giây.`,
                afterClose: handleClose
            });
            const timer = setInterval(() => {
                secondsToGo -= 1;
                instance.update({
                    content: `Di chuyển về trang chủ sau ${secondsToGo} giây.`,
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(timer);
                instance.destroy();
            }, secondsToGo * 1000);
        }

    };

    const handleSubmit = async (values) => {
        let username = values.userName
        let email = values.email
        let password = values.password

        // Gửi yêu cầu đăng ký tài khoản đến server
        const response = await fetch('https://api.ukm.vn/api/account/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify({username, email, password})
        });
        if (response.ok) {
            console.log('Request thành công!');
            setLoadings([]);


            // Xử lý chuyển hướng đến trang đăng nhập hoặc thông báo đăng ký thành công
            const data = await response.json();
            (data.status === true) ? countDown(true, data) : countDown(false, data)
            console.log(data)
        } else {
            console.error('Đăng ký tài khoản thất bại.');
            // Xử lý hiển thị thông báo lỗi đăng ký tài khoản
        }

    };
    return (
        <div>
            <Layout style={{minHeight: '100vh'}}>

                <HeaderBar/>
                <Layout>
                <Sidebar/>
                <Content style={{margin: '0 16px'}}>
                    <div style={{padding: 24, minHeight: 360, background: colorBgContainer}}>
                        <h1>Đăng ký</h1>
                        <Form labelCol={{span: 3}}
                              wrapperCol={{span: 10}}
                              autoComplete="off"
                              onFinish={(values) => {
                                  handleSubmit(values)
                                  enterLoading(1)

                              }
                              }
                              onFinishFailed={(error) => {
                                  console.log(error)
                              }}
                        >
                            <Form.Item
                                name="userName"
                                label="Username"
                                rules={[{
                                    required: true,
                                    message: "Thiếu tên tài khoản",
                                }, {
                                    whitespace: true,
                                    message: "Không thể điền khoảng trắng"
                                }, {
                                    min: 5,
                                    message: "Tối thiểu 5 kí tự"
                                }]}
                                hasFeedback
                            >
                                <Input
                                    allowClear
                                    placeholder="Type username"
                                />
                            </Form.Item>

                            <Form.Item name="email"
                                       label="Email"
                                       rules={[{
                                           required: true,
                                           message: "Thiếu tên tài khoản"
                                       }, {
                                           type: "email",
                                           message: "Địa chỉ email đang sai"
                                       }]}
                                       hasFeedback
                            >
                                <Input
                                    allowClear
                                    placeholder="Type email"
                                />
                            </Form.Item>
                            <Form.Item name="password" label="Password"
                                       rules={[
                                           {
                                               required: true,
                                               message: "Vui lòng nhập mật khẩu"
                                           },
                                           {
                                               min: 6,
                                               message: "Tối thiểu 6 kí tự"
                                           },
                                       ]
                                       }>
                                <Input.Password
                                    allowClear
                                    placeholder="Nhập mật khẩu"
                                />
                            </Form.Item>
                            <Form.Item name="rePassword" label="Re Password"
                                       rules={[{
                                           required: true,
                                           message: "Vui lòng nhập lại mật khẩu"
                                       }, ({
                                               getFieldValue
                                           }
                                       ) => ({
                                           validator(_, value) {
                                               if (!value || getFieldValue('password') === value) {
                                                   return Promise.resolve()
                                               }
                                               return Promise.reject("Mat khau khong trung")

                                           }
                                       })
                                       ]}
                                       hasFeedback>
                                <Input.Password
                                    allowClear
                                    placeholder="Nhập lại mật khẩu"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button block
                                        type="primary"
                                        htmlType="submit"
                                        loading={loadings}
                                >
                                    Đăng ký
                                    {contextHolder}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Content>
                </Layout>
            </Layout>

        </div>
    );
};

export default Register;
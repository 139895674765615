import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Layout, Modal, Select, Table, Tag, notification} from "antd";
import HeaderBar from "../../../layouts/HeaderBar";
import Sidebar from "../../../layouts/Sidebar";
import {Content} from "antd/lib/layout/layout";
import {useNavigate, useParams} from "react-router-dom";
import {requestPostAPI, requestSimpleGetApi} from "../../../requestToAPI";
import {useAuthCheck} from "../../../useAuthCheck";

const {Option} = Select;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};
const CollectionCreateForm = ({open, onCreate, onCancel, confirmLoading}) => {
    const [form] = Form.useForm();
    return (
        <Modal
            open={open}
            title="Thêm tài khoản ngân hàng"
            okText="Thêm"
            cancelText="Huỷ"
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        console.log(values)
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                {...formItemLayout}
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    name="username"
                    label="Tên đăng nhập"
                    rules={[{
                        required: true,
                        message: "Thiếu tên tài khoản",
                    }]}
                >
                    <Input
                        allowClear
                        placeholder="Type username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Mật khẩu"
                    rules={[{
                        required: true,
                        message: "Thiếu mật khẩu",
                    }]}
                >
                    <Input.Password
                        allowClear
                        placeholder="Type password"
                    />
                </Form.Item>
                <Form.Item
                    name="bankName"
                    label="Chọn ngân hàng"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng chọn ngân hàng!',
                        },
                    ]}
                >
                    <Select placeholder="Ngân hàng">
                        <Option value="vcb">Vietcombank</Option>
                        <Option value="vtb">Vietinbank</Option>
                        <Option value="mbb">MB Bank</Option>
                        <Option value="bidv">BIDV</Option>
                        <Option value="tpb">TP Bank</Option>
                        <Option value="vpb">VP Bank</Option>
                        <Option value="acb">AC Bank</Option>
                        <Option value="tcb">Techcombank</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="numberAccount"
                    label="Số tài khoản"
                    rules={[{
                        required: true,
                        message: "Thiếu số tài khoản",
                    }]}
                >
                    <Input
                        allowClear
                        placeholder="Type account number"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
const ListBank = () => {
    //-------------------------------------------------------------
    let navigate = useNavigate()
    useAuthCheck();
    //--------------------------------------------------------------
    // Xử lý hiển thị danh sách tài khoản
    const [listBank, setListBank] = useState('')
    const [loading, setLoading] = useState(true)
    const [columns] = useState(
        [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 40
        }, {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            render: ((_, {username}) => {
                return (
                    <a>{username}</a>
                )
            })
        }, {
            title: 'Số tài khoản',
            dataIndex: 'numberAccount',
        }, {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
        }, {
            title: 'Trạng thái',
            dataIndex: 'isWorking',
            render: (_, {isWorking}) => {
                let color;
                let text;
                if (isWorking === '?') {
                    color = 'orange'
                    text = "????"
                } else if (isWorking === '1') {
                    color = 'green'
                    text = "WORKING"
                } else if (isWorking === null) {
                    color = 'rgba(0, 0, 0, 0.88)'
                    text = "NULL"
                } else if (isWorking === '0') {
                    color = 'rgba(255,0,0,0.88)'
                    text = "STOPED"
                }
                return (
                    <Tag color={color} key={isWorking}>
                        {text}
                    </Tag>
                )

            }
        }
        ]
    )
    useEffect(() => {
        requestSimpleGetApi('manage/bank/bank-account')
            .then((data) => {
                if (data.status === false) {
                    setListBank(false)
                    console.log('Không có data')
                } else {
                    console.log('Tìm thấy data')
                    setListBank(data.data)
                    setLoading(false)
                }
            })
    }, [])

    //-----------------------------------------
    // Hiển thị Modal thêm 1 tài khoản ngân hàng
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const onCreate = async (values) => {
        let formData = {
            username: values.username,
            password: values.password,
            bankName: values.bankName,
            numberAccount: values.numberAccount
        }
        setConfirmLoading(true)
        let data = await requestPostAPI('manage/bank/bank-account', formData)
        console.log("Response: " + JSON.stringify(data))
        if (data.id) {
            openNotification('top', 'Tài khoản ngân hàng', 'Đã thêm thành công')
            setConfirmLoading(false)
            setOpen(false)


        }
    }
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
        });
    };


    const clickRow = (e, record) => {
        console.log(record)
        switch (record.bankName) {
            case "vcb":
                navigate(`/bank/vietcombank/bankinfo/${record.id}`)
                break
            case "vtb":
                console.log("vtb")
                break
            case "mbb":
                console.log("mbb")
                break
            case "bidv":
                console.log("bidv")
                break
            case "acb":
                console.log("acb")
                break
            case "tpb":
                console.log("tpb")
                break
            case "vpb":
                console.log("vpb")
                break
            case "sab":
                console.log("sab")
                break
            case "msb":
                console.log("msb")
                break
            default:
                navigate(`/bank`)
        }
        //
    }


    return (
        <Layout>
            <HeaderBar/>
            <Layout style={{
                minHeight: "100vh"
            }}>
                <Sidebar/>
                <Content style={{margin: '10px 20px'}}>
                    <Button
                        type={"primary"}
                        size={"large"}
                        onClick={() => setOpen(true)}
                    >Thêm tài khoản
                    </Button>
                    <Table
                        dataSource={listBank}
                        columns={columns}
                        rowKey={(listBank) => listBank.id}
                        bordered
                        title={() => 'Danh sách tài khoản'}
                        size={"middle"}
                        loading={loading}

                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => {
                                    clickRow(event, record)
                                }
                            }
                        }}
                    />
                    <div>
                        {contextHolder}
                        <CollectionCreateForm
                            open={open}
                            onCreate={onCreate}
                            onCancel={() => {
                                setOpen(false);
                            }}
                            confirmLoading={confirmLoading}
                        />
                    </div>
                </Content>


            </Layout>

        </Layout>
    );
};

export default ListBank;
import React, {useState} from 'react';
import {Button, Card, Modal, Space} from "antd";

const NotifyLogin = ({ModalOpen, title, des, handleOk}) => {

    //
    console.log("Goi function")
    // const handleOk = () => {
    //     // setIsModalOpen(false);
    //     ModalOpen = false
    // };
    return (
        <>
            <Modal
                title={title}
                open={ModalOpen}
                onOk={handleOk}
                cancelButtonProps={{
                    disabled: true,
                    ghost: true,
                }}
                width="40vw"
            >
                <p>{des}</p>
            </Modal>
        </>
    );
};

export default NotifyLogin;

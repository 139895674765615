import React from 'react';
import HeaderBar from "../../../layouts/HeaderBar";
import {Layout} from "antd";
import Sidebar from "../../../layouts/Sidebar";

const {Content} = Layout;

const MBBIndex = () => {
    return (
        <Layout>
            <HeaderBar/>
            <Layout>
                <Sidebar/>
                <Content>
                    <h1>MBBbank Index</h1>
                </Content>
            </Layout>
        </Layout>
    );
};

export default MBBIndex;
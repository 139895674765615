import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {checkToken} from "./requestToAPI";

export function useAuthCheck() {
    const navigate = useNavigate();

    useEffect(() => {
        checkToken()
            .then((data) => {
                if (!data || data.status !== true) {
                    navigate('/login');
                }
            });
    }, [navigate]);
}
import React, {useState} from 'react';
import {Button, Card, Modal, Popconfirm, Space} from "antd";


const FirstNotify = () => {
    const [openModal] = useState(() => {
        let dataLocalStorage = localStorage.getItem('modalAddAccountNotifyVietcombank')
        return dataLocalStorage !== 'true';
    });
    const [isModalOpen, setIsModalOpen] = useState(openModal);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        localStorage.setItem('modalAddAccountNotifyVietcombank', true)
        setIsModalOpen(false);
    };
    return (
        <>
            <Space>
                <Card
                    style={{
                        width: "80vw",
                        marginBottom: 20,
                        textAlign: "center"
                    }}
                >
                    <Button
                        type="primary"
                        onClick={showModal}
                        danger
                    >
                        Lưu ý Vietcombank
                    </Button>
                </Card>

            </Space>

            <Modal
                title="Lưu ý"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelText="Không hiển thị"
                width="60vw"
            >
                <p>Vietcombank update tắt đăng nhập trên web https://vcbdigibank.vietcombank.com.vn.</p>
                <p>Để API hoạt động thì mọi người cần đăng nhập được lên đây. Các bạn sử dụng Vietcombank vui lòng cho phép đăng nhập trên Web Digital vietcombank bằng cách: Truy cập vào App Vietcombank -> Cài đặt -> Cài đặt chung -> Cài đặt đăng nhập -> "Tắt" Cài đặt đăng nhập VCB trên trình duyệt web.</p>
                <p>Lưu ý sử dụng Vietcombank:</p>
                <p>- Không đăng nhập lưu trình duyệt trên trình duyệt Web tại Website VCBDigital.vietcombank.com.vn vì Vietcombank chỉ cho lưu tối đa 5 trình duyệt.</p>
                <p>- OTP đăng nhập chỉ 1 lần duy nhất trên hệ thống API. Không xóa tài khoản ra API. Mọi chi tiết cần hỗ trợ liên hệ Zalo: 000000000</p>
                <p>Lưu ý sử dụng Vietcombank:</p>
            </Modal>
        </>
    );
};

export default FirstNotify;

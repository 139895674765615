import config from "../../config";

export async function checkToken() {
    const token = localStorage.getItem('token'); // Lấy token từ localStorage
    if (!token) {
        // Nếu không có token, chuyển hướng đến trang đăng nhập
        return {status: false, des: 'Have not token'}
    } else {
        // Gửi yêu cầu đến server để kiểm tra tính hợp lệ của token
        let url = config.API_BASE_URL + 'account/info'
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token})
        });
        if (response.ok) {
            console.log('Send request verify success!');
            // Xử lý chuyển hướng đến trang đăng nhập hoặc thông báo đăng ký thành công
            return await response.json()
        } else {
            console.error('Send request verify failed');
            return false
            // return await response.json()
        }
    }
}

export async function logoutToken() {
    const token = localStorage.getItem('token'); // Lấy token từ localStorage
    if (!token) {
        // Nếu không có token, chuyển hướng đến trang đăng nhập
        return {status: false, des: 'Have not token'}
    } else {
        let url = config.API_BASE_URL + 'account/logout'
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token})
        });
        if (response.ok) {
            console.log('Send request logout success!');
            localStorage.removeItem('token')
            return await response.json()
        } else {
            console.error('Send request verify failed');
            return await response.json()
        }
    }
}

export async function requestPostAPI(uri, formData, hasToken = true) {
    const token = localStorage.getItem('token')
    if (hasToken) {
        if (!token) {
            return {status: false, des: 'Have not token'}
        }
    }
    let url = config.API_BASE_URL + uri
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const formdata = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
        formdata.append(key, value);
    });

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions)
    if (response.ok) {
        console.log(`Send request ${uri} success!`);
        return await response.json()
    } else {
        console.error(`Send request ${uri} failed!`);
        return await response.json()
    }
}
export async function requestGetAPI(uri, hasToken = true) {
    const token = localStorage.getItem('token')
    if (hasToken) {
        if (!token) {
            return {status: false, des: 'Have not token'}
        }
    }
    let url = config.API_BASE_URL + uri
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions)
    if (response.ok) {
        console.log(`Send request GET ${uri} success!`);
        return await response.json()
    } else {
        console.error(`Send request GET ${uri} failed!`);
        return await response.json()
    }
}
export async function requestCustomMethod(uri, method, hasToken = true) {
    const token = localStorage.getItem('token')
    if (hasToken) {
        if (!token) {
            return {status: false, des: 'Have not token'}
        }
    }
    let url = config.API_BASE_URL + uri
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    const requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: 'follow'
    };
    const response = await fetch(url, requestOptions)
    if (response.ok) {
        console.log(`Send request ${method} ${uri} success!`);
        return await response.json()
    } else {
        console.error(`Send request ${method} ${uri} failed!`);
        return await response.json()
    }
}

export async function requestSimpleGetApi(uri) {
    const token = localStorage.getItem('token')
    if (!token) {
        return {status: false, des: 'Have not token'}
    } else {
        let url = config.API_BASE_URL + uri
        console.log(url)
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        if (response.ok) {
            console.log('Send request GET API success!');
            return await response.json()
        } else {
            console.error('Send request GET API failed');
            return await response.json()
        }
    }
}
import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Layout, Select, Table, Tag, notification, Card, Space, Alert} from "antd";
import HeaderBar from "../../../layouts/HeaderBar";
import Sidebar from "../../../layouts/Sidebar";
import {Content} from "antd/lib/layout/layout";
import {useNavigate, useParams} from "react-router-dom";
import {requestPostAPI, requestSimpleGetApi} from "../../../requestToAPI";
import FirstNotify from "../element/modal/vietcombank/FirstNotify";
import {useAuthCheck} from "../../../useAuthCheck";
const {Option} = Select;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};



const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const AddBankVietcombank = () => {
    //-------------------------------------------------------------
    let navigate = useNavigate()
    useAuthCheck();
    //--------------------------------------------------------------

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement, message, description) => {
        api.info({
            message: message,
            description: description,
            placement,
            onClose: () => {
                navigate('/bank/vietcombank')
            }
        });
    };
    const onCreate = async (values) => {
        let formData = {
            username: values.username,
            password: values.password,
            bankName: 'vcb',
            numberAccount: values.numberAccount
        }
        let data = await requestPostAPI('manage/bank/bank-account', formData)
        console.log("Response: " + JSON.stringify(data))
        if (data.id) {
            console.log(data)
            openNotification('top', 'Tài khoản ngân hàng', 'Đã thêm thành công')
            setConfirmLoading(false)
        }
    }
    const onFinish = async (values) => {
        setConfirmLoading(true)
        console.log('Success:', values);
        await onCreate(values)
    };
    return (
        <Layout>
            <HeaderBar/>
            <Layout style={{
                minHeight: "100vh"
            }}>
                <Sidebar/>
                <Content style={{margin: '10px 20px'}}>
                    <FirstNotify/>
                    {contextHolder}
                    <Card
                        title="Thêm tài khoản Vietcombank"
                        style={{
                            width: "80vw",
                            marginBottom: 20
                        }}
                    >
                        <Form
                            {...formItemLayout}
                            form={form}
                            name="form_in_modal"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="username"
                                label="Tên đăng nhập"
                                rules={[{
                                    required: true,
                                    message: "Thiếu tên tài khoản",
                                }]}
                            >
                                <Input
                                    allowClear
                                    placeholder="Type username"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Mật khẩu"
                                rules={[{
                                    required: true,
                                    message: "Thiếu mật khẩu",
                                }]}
                            >
                                <Input.Password
                                    allowClear
                                    placeholder="Type password"
                                />
                            </Form.Item>
                            <Form.Item
                                name="numberAccount"
                                label="Số tài khoản"
                                rules={[{
                                    required: true,
                                    message: "Thiếu số tài khoản",
                                }]}
                            >
                                <Input
                                    allowClear
                                    placeholder="Type account number"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary"
                                        htmlType="submit"
                                        loading={confirmLoading}
                                >
                                    Thêm tài khoản
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>

                </Content>
            </Layout>
        </Layout>

    );
};

export default AddBankVietcombank;
